/* Input content */
.is-invalid {
  border-color: red;
}

.invalid-feedback {
  color: red;
}

/* .submitted-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
*/

span.submitted-state  {
  font-family: 'Courier New', Courier, monospace;
}

span.submitted-state p {
  background-color: white;;
  font-weight: 600;
}

.input-content {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 23px;
}

.codeblock {
  padding: 20px;
  white-space: pre;
  background-color: #f4f2f0;
  font-family: 'Courier New', Courier, monospace;
}

.custom-pre {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #f5f2f0;
}

.codeblock:has(.custom-pre) {
  background-color: #f5f2f0 !important;
  color: black !important;
}

.input-content .codeblock {
  background-color: #2d2d2d; /* Dark blue background */
  color: #ffffff; /* White text */
  padding: 10px;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
  /* white-space: pre-wrap; */
  border: 0px;
}

.input-content .codeblock textarea {
  background-color: transparent;
  border: none;
  color: inherit;
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
}
/* You can also specify different styles for different states */
input:focus::placeholder {
  color: rgba(211, 211, 211, 0.554) !important;
}

/* You can also specify different styles for different states */
input::placeholder {
  color: lightgray !important;
}

textarea:focus::placeholder {
  color: rgba(211, 211, 211, 0.554) !important;
}

textarea::placeholder {
  color: lightgray !important;
}

.input-content .codeblock pre {
  margin: 0;
  font-size: 20px;
}

.input-content .codeblock .line-number {
  display: inline-block;
  width: 30px;
  user-select: none;
  opacity: 0.5;
}

.code-input-wrapper {
  display: flex;
  position: relative;
}

.line-numbers {
  padding: 10px;
  background-color: #2d2d2d; /* Dark blue background */
  color: #c7c0c0; /* White text */
  text-align: right;
  /* border-radius: 5px 0 0 5px; */
  border: 0px;
}

.code-input-wrapper .form-control {
  border-radius: 0 5px 5px 0;
  margin-left: -1px; /* Adjust to align with line numbers */
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 20px;
}

.code-input-wrapper .form-control:focus {
  background-color: #2d2d2d; /* Dark blue background */
  color: #ffffff; /* White text */
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
