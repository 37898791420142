html, body {
  height: 100%;
  margin: 0;
}

.app-container {
  /* background-color: #ebf3ff; */
  max-width: auto;
  margin: 0 auto;
  /* padding: 20px; */
  position: relative; /* Ensure positioning context for popup */
  height: 100vh
}