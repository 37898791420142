/* inputShellContentStyles.css */

.shell-content {
  background-color: #000;
  color: #c7c7c7;
  font-family: monospace;
  padding: 1rem;
  position: relative;
}

.shell-output {
  overflow-y: auto;
  max-height: 300px;
}

.shell-prompt {
  color: #00ff00;
}

.shell-response {
  margin-left: 1.5rem;
}

.shell-input {
  display: flex;
  align-items: center;
}

.shell-input input {
  background-color: transparent;
  border: none;
  color: #c7c7c7;
  flex-grow: 1;
  outline: none;
  font-family: monospace;
}

.shell-success {
  color: #00ff00;
}

.shell-footer {
  margin-top: 10px;
}

.progress-dots {
  display: flex;
  align-items: center;
}

.progress-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 5px;
}

.shell-footer .btn {
  align-self: flex-end;
}

.shell-text-content {
  /* font-family: 'Courier New', Courier, monospace; */
  line-height: 1.5;
  white-space: pre-wrap;
  /* font-weight: 600; */
  font-size: 23px;
  background: #eef3ff;
  color: #22242c;
  padding: 10px;
  border-radius: 4px;
}
