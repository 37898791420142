/* Container */
.container {
  padding-top: 70px; /* Adjust this value if your navbar height changes */
}

/* App container */
.app-container {
  /* background-color: #ebf3ff; */
  max-width: auto;
  margin: 0 auto;
  /* padding: 20px; */
  position: relative; /* Ensure positioning context for popup */
}

/* Dimmed background */
.dimmed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100; /* Ensure the dimmed background is below the popup but above other content */
}

/* Show/Hide reference button */
/* .show-reference-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1300;
} */

/* Step container */
/* .step-container {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 1rem;
  margin-bottom: 1rem;
} */

.upgrade-notice{
  color: red;
}

.pricing-container {
  display: flex;
  align-items: center; /* Center items vertically */
}

.unlock-button {
  margin-right: 8px; /* Optional: Add some space between the button and the text */
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  left: -136px;
  z-index: 1400;
}

@media (max-width: 767.98px) {
  .btn-mobile-top {
    top: 130px !important;
  }
}

.progress-container-mobile {
  background-color: white;
  position: fixed;
  top: 48px;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other content */
  padding: 0.5rem 1rem; /* Add some padding for better appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better visibility */
}


.button-container{
  position: fixed; /* Ensure the container is fixed */
  bottom: 0;
  right: 0;
  margin: 3px;
  display: flex;
  align-items: center;
  gap: 20px; /* 20px padding between buttons */
  z-index: 1500;
}
