/* src/components/Course/courseListStyles.css */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.course-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.course-card {
  display: flex;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
}

.course-thumbnail {
  width: 150px;
  height: auto;
  object-fit: cover;
}

.course-info {
  padding: 20px;
  flex-grow: 1;
}

.course-info h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.course-info p {
  flex-grow: 1;
}

.course-info .btn {
  margin-top: 10px;
}

.lock {
  width: 20px;
}

.in-development{
  color: #9b8dd4;
  font-weight: 600;
}

.live-badge {
  display: inline-block;
  margin-left: 8px;
  font-size: 15px !important;
  vertical-align: top !important;
}

.tooltip{
  font-size: 20px;;
}
