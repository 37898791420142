.cover-image {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px;
  top: 60px;
  border-radius: 6px;
  flex-direction: column; /* Stack content vertically on mobile */
}

.cover-content {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.082);
}

.cover-text {
  padding: 20px;
  text-align: center; /* Center text on mobile */
}

.cover-text h1 {
  margin: 0;
  font-size: 36px;
}

.cover-text .subtitle {
  font-size: 18px;
  margin: 10px 0;
}

.cover-text .description {
  font-size: 14px;
}

.cover-thumbnail {
  width: 150px;
  height: 150px;
  margin-top: 20px; /* Add margin to separate from text */
}

.cover-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .cover-content {
    flex-direction: row; /* Align content horizontally on larger screens */
    justify-content: space-between;
  }

  .cover-text {
    text-align: left; /* Align text to the left on larger screens */
  }

  .cover-thumbnail {
    margin-top: 0; /* Remove margin on larger screens */
  }
}