/* certificateContentStyles.css */

.certificate-content {
  text-align: center;
  background-color: #b3d2e4;
}

.certificate-template {
  width: 100%; /* Use full width */
  height: auto;
  position: relative;
  padding: 50px;
  background-color: #fff;
  color: #000;
  /* Set landscape orientation */
  transform: rotate(0deg);
}

.certificate-template h1 {
  font-size: 20px;
  margin-bottom: 0;
}

.certificate-template h2 {
  font-size: 18px;
  margin: 20px 0;
}

.certificate-template h3 {
  font-size: 16px;
  margin: 20px 0;
}

.certificate-template p {
  font-size: 12px;
}

.certificate-logo {
  width: 150px;
  margin-bottom: 20px;
}

.signature {
  margin-top: 40px;
}

.signature img {
  width: 100px;
}

.course-complete-image {
  margin-top: 20px;
  width: 100px;
}

@media print {
  body {
    margin: 0;
  }
  .certificate-template {
    width: 100%;
    height: auto;
    page-break-after: always;
  }
}
