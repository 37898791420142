/* Reference popup */
h1.reference-title {
  /* font-size: 1.5rem; */
}

.reference-popup {
  position: fixed; /* Fix position so it doesn't affect layout */
  width: 50vw;
  height: 100%;
  box-shadow: 0 0 10px rgba(
    0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1200; /* Ensure the popup is above other content */
  right: 0; /* Ensure the popup is aligned to the right */
  top: 0px; /* Ensure the popup is aligned to the top */
  background-color: #fff; /* Ensure the popup background is white */
}
.reference-popup pre code {
  text-shadow: none;
}

.token.operator,
.token.punctuation {
  background-color: transparent !important;
}

@media (max-width: 767.98px) {
  .reference-popup {
    width: 100vw; /* Make it full width on mobile */
  }
}

/* Dimmed background */
.dimmed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100; /* Ensure the dimmed background is below the popup but above other content */
}

/* Show/Hide reference button */
.show-reference-btn {
  position: fixed;
  bottom: 10px;
  left: 0px;
  z-index: 1500; /* Ensure the button is above the popup */
  width: 150px;
}
