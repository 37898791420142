.code-content {
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: white; /* Monokai background color */
}

.code-content pre {
  /* background: #272822; */ /* Monokai background */
  padding: 1rem; /* Add padding for better visibility */
  margin: 0;
  white-space: pre; /* Preserve whitespace */
  overflow: auto; /* Allow scrolling if content overflows */
  /* color: #f8f8f2;  */ /* Monokai text color */
}

.code-content code {
  background: none; /* Monokai theme already provides the background */
  padding: 0.25rem;
  margin: 0 0.25rem;
  font-family: 'Courier New', Courier, monospace;
  color: inherit; /* Inherit color from theme */
  display: block;
  font-size: 20px;
}

.result-section {
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-top: 1rem;
  font-size: 20px;
}

.submitted-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.submitted-state span {
  font-weight: 600;
  font-family: 'Courier New', Courier, monospace;
}


.code-output {
  text-align: right;
  color: grey;
}

.pre-run-description { 
  line-height: 1.5;
  white-space: pre-wrap;
}

.pre-run-description code {
  font-size: .875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
  display: inline-block;
}