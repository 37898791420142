.code-diff-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.code-diff-content .inserted,
.code-diff-content .token.inserted {
  color: #98c379;
  background-color: #e6ffec;
}

.code-diff-content .deleted,
.code-diff-content .token.deleted {
  color: #e06c75;
  background-color: #ffebe9;
}

/* GitHub-style diff */
.github-style pre {
  background-color: #f6f8fa;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  font-size: 12px;
  line-height: 20px;
}

.github-style .diff-line {
  display: flex;
  padding: 0 10px;
}

.github-style .diff-line:hover {
  background-color: #fffbdd;
}

.github-style .diff-line.added {
  background-color: #e6ffec;
}

.github-style .diff-line.removed {
  background-color: #ffebe9;
}

.github-style .line-number {
  color: rgba(27,31,35,0.3);
  padding-right: 10px;
  text-align: right;
  width: 40px;
  user-select: none;
}

.github-style .line-content {
  white-space: pre;
}

.github-diff {
  display: block;
}

/* Standard diff view */
.code-diff-content:not(.github-style) .diff-line {
  display: block;
  white-space: pre-wrap;
}

.code-diff-content:not(.github-style) .line-content {
  display: inline-block;
  width: 100%;
}
