/* Text content */
.text-content p {
  /* font-family: 'Courier New', Courier, monospace; */
  line-height: 1.5;
  white-space: pre-wrap;
  /* font-weight: 600; */
  font-size: 23px;
  background: #eef3ff;
  color: #22242c;
  padding: 10px;
  border-radius: 4px;
}

.text-content code {
  background-color: white;
  border-radius: 0.125rem;
  padding: 0.25rem;
  margin: 0 0.25rem;
  font-family: 'Courier New', Courier, monospace;
}