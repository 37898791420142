/* Current step */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(135deg, #f8b7cc, #b5cfe8, #a8e5d9);
  background-attachment: fixed; /* Ensure the background stays fixed during scrolling */
  background-size: cover; /* Ensure the gradient covers the entire area */
  min-height: 100vh; /* Ensure the body extends to at least the height of the viewport */
  display: flex;
  flex-direction: column;
}

.current-step {
  border: 2px solid #007bff;
}

/* Completed state */
.completed {
  font-size: 24px;
  color: green;
}