/* Navbar */
.navbar {
  z-index: 1400;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-brand {
  font-size: 1rem;
  margin-right: auto;
  padding-left: 20px;
}

.navbar-nav .nav-link {
  padding-left: 20px;
  padding-right: 20px;
}

.progress-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.progress-container span {
  margin-right: 10px;
}

.progress-container .progress-bar {
  flex-grow: 1;
  margin-left: 10px;
  width: 200px; /* Ensure the progress bar is visible */
  background-color: #f7eef7;
}

/* Progress bar */
.progress-bar {
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #f7eef7 !important;
}

.progress-bar-fill {
  height: 100%;
  background-color: #007bff;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.custom-dropdown {
  position: relative; /* Ensure the dropdown is positioned relative to its parent */
}
.custom-dropdown .dropdown-toggle::after {
  display: none; /* Hide the dropdown icon */
}

.logo {
  width: 200px;
}

.basic-nav-dropdown {
  z-index: 00 !important; /* Bring it above other elements */
}
