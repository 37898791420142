.instruction-content {
  background-color: #fff;
}

.instruction-content h3 {
  margin-bottom: 1rem;
}

.instruction-content .list-group-item {
  display: flex;
  align-items: center;
}

.instruction-content .form-check-label {
  margin-left: 0.5rem;
}
