/* src/components/Course/courseStyles.css */

.body {
  background: #eaf3ff;
}

.course-container {
  padding: 20px;
  background: 'eaf3ff';
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.level-container {
  margin-bottom: 30px;
}

.lessons-list {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.lesson-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.lesson-item:last-child {
  border-bottom: none;
}

.lesson-info {
  display: flex;
  align-items: center;
}

.lesson-title {
  font-size: 16px;
  font-weight: 500;
}

.lesson-icon {
  margin-right: 10px;
}

.lock {
  margin-left: 10px;
}

.course-about{
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.count-about{
  background: #f8f9fa;
  border-radius: 8px;
  padding: 5px;
}

.instructor-info {
  display: flex;
  align-items: center; /* Center the image and text vertically */
}

.profile-image {
  width: 40px; /* Set the desired width */
  height: 40px; /* Set the desired height */
  border-radius: 50%; /* Make the image round */
  margin-right: 8px; /* Space between the image and the name */
  object-fit: cover; /* Ensure the image covers the circle without distortion */
}

.instructor-name {
  margin: 0; /* Remove default margin for better alignment */
  line-height: 40px; /* Match the height of the image for vertical alignment */
}

.badge-dark{
  background: black;
}

.view-toggle {
  margin-bottom: 20px;
}

.view-toggle button {
  margin-right: 10px;
  padding: 10px;
}

.view-toggle .active {
  font-weight: bold;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
}

.progress-insight{
  font-size: 25px;
  font-weight: 600;
  color: #0e2c47;

}

.live-badge {
  display: inline-block;
  margin-left: 8px;
  font-size: 15px !important;
  vertical-align: top !important;
}

/* Set the calendar background to white */
.fc {
  background-color: white;
}

/* Optional: Adjust text color and event styles if needed */
.fc .fc-daygrid-day-number,
.fc .fc-event-title {
  color: black;
}

.fc .fc-toolbar.fc-header-toolbar {
  background-color: white;
}

.fc .fc-toolbar-title {
  font-size: 1em;
  color: black;
}
