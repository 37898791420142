.lesson-header {
  background-color: #1e1e2e;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.epic-tag {
  display: inline-block;
  background-color: #3498db;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.user-story {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #ecf0f1;
}

.description {
  font-size: 1rem;
  color: #bdc3c7;
  margin-bottom: 1rem;
}

.tech-stack, .acceptance-criteria {
  margin-top: 1rem;
}

.tech-stack h3, .acceptance-criteria h3 {
  font-size: 1.1rem;
  color: #ecf0f1;
  margin-bottom: 0.5rem;
}

.tech-stack ul, .acceptance-criteria ul {
  list-style-type: none;
  padding-left: 0;
}

.tech-stack li, .acceptance-criteria li {
  color: #bdc3c7;
  margin-bottom: 0.3rem;
}

@media (max-width: 768px) {
  .lesson-header {
    padding: 1rem;
  }

  .user-story {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
  }
}
