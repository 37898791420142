.quiz-content {
  background-color: #fff;
}

.quiz-content h5 {
  margin-bottom: 1rem;
}

.quiz-content .form-check {
  margin-bottom: 0.5rem;
}
