.html-content {
  background-color: #fff;
}

.html-content pre {
  /* background-color: #272822; */
  color: #f8f8f2;
  padding: 1rem;
}

.output-label {
  font-weight: bold;
}

.output-render {
  border: 1px solid #ddd;
  padding: 1rem;
  min-height: 100px;
  background-color: #f9f9f9;
}
