.input-html-content {
  font-size: 23px;
  background: #eef3ff;
  color: #22242c;
  padding: 10px;
  border-radius: 4px;
}

.input-html-content .code-input {
  background-color: #f5f2f0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
}

.output-label {
  font-weight: bold;
}

.output-render {
  border: 1px solid #ddd;
  padding: 1rem;
  min-height: 100px;
}

.code-editor {
  border: 1px solid #ddd;
}

.code-editor textarea {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  resize: none;
}

.code-editor pre {
  margin: 0;
}

.checkmark {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.checkmark svg {
  margin-right: 5px;
}

/* inputHTMLContentStyles.css */

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}
