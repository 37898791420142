/* inputShellContentStyles.css and shellContentStyles.css */

.shell-content {
  background-color: #000;
  color: #c7c7c7;
  font-family: monospace;
  padding: 1rem;
}

.shell-output {
  overflow-y: auto;
  max-height: 300px;
}

.shell-prompt {
  color: #00ff00;
}

.shell-response {
  margin-left: 1.5rem;
}

.shell-input {
  display: flex;
  align-items: center;
}

.shell-input input {
  background-color: transparent;
  border: none;
  color: #c7c7c7;
  flex-grow: 1;
  outline: none;
  font-family: monospace;
}

.shell-success {
  color: #00ff00;
}
